import { Space } from "antd";
import React from "react";
import bg from "../images/Black.png";
import "../styles.css";
const Strip = (props) => {
  return (
    <div className={props.bg} id="offer">
      <img
      //  src={props.bg}
      ></img>
    </div>
  );
};

export default Strip;
