import { Col, Row } from "antd";
import {
  Html5Filled,
  FacebookFilled,
  AndroidFilled,
  AppleFilled,
} from "@ant-design/icons";
import React from "react";
import "../styles.css";
import display from "../images/Display.svg";
import create from "../images/Create.svg";
import schedule from "../images/schedule.svg";
import remote from "../images/remote-access.svg";
import playlist from "../images/playlist.png";
import pushtoall from "../images/pushtoall.png";
import rotatescreen from "../images/rotatescreen.png";
import splitscreen from "../images/split-screen.png";
const OurFeatures = () => {
  return (
    <div
      id="features"
      style={{
        fontFamily: "Raleway",
        backgroundColor: "#FBC02D",
      }}
    >
      <Row
        justify="center"
        style={{
          fontFamily: "Raleway",
          backgroundColor: "#FBC02D",
        }}
      >
        <Col xl={{ span: 20 }} xs={20}>
          <h1
            style={{
              //   display: "flex",
              //   justifyContent: "center",
              fontSize: "40px",
              fontFamily: "Raleway",
            }}
          >
            Explore Our Features
          </h1>

          {/* <p
            style={{
              //   display: "flex",
              //   justifyContent: "center",
              fontSize: "16px",
              fontFamily: "Raleway",
              // width: "60%",
              lineHeight: "25px",
            }}
          >
            I'm a paragraph. Click here to add your own text and edit me. It’s
            easy. Just click “Edit Text” or double click me to add your own
            content and make changes to the font.
          </p> */}
        </Col>
      </Row>
      <div
        style={{
          marginBottom: "50px",
          fontFamily: "Raleway",
          backgroundColor: "#FBC02D",
          padding: "30px",
        }}
      >
        <Row justify="center">
          <Col xl={5} className="feature-icons">
            <div>
              <img src={display} style={{ width: "80px" }}></img>
              {/* <Html5Filled /> */}
            </div>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                {" "}
                Display Content
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Raleway",
                }}
              >
                Display a slideshow of images and videos or a single video or an
                image, any combination you prefer.
              </p>
            </Row>
          </Col>
          <Col xl={5} className="feature-icons">
            <img src={remote} style={{ width: "80px" }}></img>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Remote Access
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  fontFamily: "Raleway",
                }}
              >
                Access and upload content from anywhere around the world.
              </p>
            </Row>
          </Col>
          <Col xl={5} className="feature-icons">
            <img src={schedule} style={{ width: "80px" }}></img>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Schedule Content
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  fontFamily: "Raleway",
                }}
              >
                Don't worry about forgetting to upload content on right time,
                schedule them ahead of time and forget about it.
              </p>
            </Row>
          </Col>{" "}
          <Col xl={5} className="feature-icons">
            <img src={create} style={{ width: "80px" }}></img>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Create Your Own Content
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  fontFamily: "Raleway",
                }}
              >
                Simple tools and readily available templates allow you to create
                your content without any skills.
              </p>
            </Row>
          </Col>
        </Row>

        <Row justify="center">
          <Col xl={5} className="feature-icons">
            <div>
              <img src={pushtoall} style={{ width: "80px" }}></img>
              {/* <Html5Filled /> */}
            </div>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Push to all screens
              </p>
            </Row>{" "}
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Raleway",
                }}
              >
                Have the same data to be pushed? Click 1 button and push content
                to all your screens
              </p>
            </Row>
          </Col>
          <Col xl={5}  className="feature-icons">
            <img src={splitscreen} style={{ width: "80px" }}></img>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Split Screen
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Raleway",
                }}
              >
                Display different content on 1 screen by splitting your image!
              </p>
            </Row>
          </Col>
          <Col xl={5}  className="feature-icons">
            <img src={rotatescreen} style={{ width: "80px" }}></img>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Rotate Screen
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Raleway",
                }}
              >
                Bored by just displaying content in Landscape Mode? Rotate your
                screen and start displaying content in potrait mode
              </p>
            </Row>
          </Col>{" "}
          <Col xl={5}  className="feature-icons">
            <img src={playlist} style={{ width: "80px" }}></img>
            <Row>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  fontWeight: "700",
                  marginBottom: "2px",
                  fontFamily: "Raleway",
                }}
              >
                Create Playlist
              </p>
            </Row>
            <Row>
              {" "}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Raleway",
                }}
              >
                Make a mix and match of Photos and Videos that suit your
                audience
              </p>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OurFeatures;
