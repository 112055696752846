import { Col, Row } from "antd";
import React from "react";
import clients from "../images/clients.webp";
import "../styles.css";
const Clients = () => {
  return (
    <div style={{ marginBlock: "50px" }}>
      <Row justify="center">
        <Col xl={15} sm={20} xs={20}>
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "50px",
              fontFamily: "Raleway",
            }}
          >
            Our Clients
          </h1>
          <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              fontSize: "16px",
              fontFamily: "Raleway",
              lineHeight: "28px",
            }}
          >
            We come in with around 5 years of experience in cloud controlled
            digital signage solutions having served top notch clients from
            various industries especially from the hospitality indusrty. We
            commit to offer the best of the best services to every client using
            our product.
          </p>
        </Col>
      </Row>
      <Row justify="center">
        {" "}
        <img style={{ width: "100%" }} src={clients}></img>
      </Row>
    </div>
  );
};

export default Clients;
