import { Card, Col, Row } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";

const OfferCard = (props) => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItem: "center",
        marginBottom: "50px",
      }}
    >
      {/* <Card
        hoverable
        style={{
          width: 280,
        }}
        // cover={}
      > */}
      <Col>
        <Row>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "3px",
              borderColor: "black",

              borderRadius: "10px",
            }}
          >
            <img
              alt="example"
              src={props.pic}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
            />
          </div>
        </Row>
        <Row>
          <div
            style={{
              fontFamily: "Raleway",
              // marginBottom: "10%",
              // marginTop: "10%",
              // padding: "25px",
              // height: "100%",
              // backgroundColor: "violet",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "20px",
            }}
          >
            <Col span={22}>
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  fontFamily: "Raleway",
                }}
              >
                {props.title}
              </h1>
              {/* <Divider style={{ borderColor: "#FBB248" }} /> */}
              <p
                style={{
                  lineHeight: "22px",
                  fontSize: "13px",
                  fontFamily: "Raleway",
                }}
              >
                {/* I'm a paragraph. Click here to add your own text and edit me.
                It’s easy. Just click “Edit Text” or double click me to add your
                own content and make changes to the font. I’m a great place for
                you to tell a story and let your users know a little more about
                you. */}
                {props.meta}
              </p>
            </Col>
          </div>
        </Row>
      </Col>

      {/* </Card> */}
    </div>
  );
};

export default OfferCard;
