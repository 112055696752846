import React, { useRef, useState } from "react";
import Layout from "./Layout.js";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Calendly from "./Components/Calendly.js";
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="scheduledemo" element={<Calendly />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
