import { Button, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import OfferCard from "../Components/OfferCard";
import delivery from "../images/delivery.webp";
import installation from "../images/installation.webp";
import selfinstallation from "../images/selfinstallation.webp";
import rounds from "../images/amit1.png";
import morerounds from "../images/amit2.png";
import { WechatOutlined } from "@ant-design/icons";
import TitleCustom from "../Components/TitleCustom";

const Info = () => {
  const data = [
    {
      title: "Deliver, Install & Train",
      meta: "In this option, we will deliver install and train the staff on how to use Ideogram so you can sit back and relax",
    },
    {
      title: "Plug and Play (If you have Android TV)",
      meta: (
        <p>
          Have an Android TV? <br /> You can download the app from the play
          store and voila you have the ideogram ready.
          <br />
          <b>NO HARDWARE COST</b>
        </p>
      ),
    },
    {
      title: "Delivery",
      meta: "We will deliver the Ideogram Android  Box and explain to you how to install and use it.",
    },
  ];
  return (
    <div
      style={{ fontFamily: "Raleway", backgroundColor: "#FBC02D" }}
      id="info"
    >
      <Row
        justify="center"
        style={{
          // marginTop: "50px",
          marginBottom: "50px",
        }}
        align="middle"
      >
        <Col
          xl={15}
          xs={20}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "pink",
          }}
        >
          {/* <img src={rounds} style={{ zIndex: "1", height: "300px" }}></img> */}

          <Col>
            <Title
              style={{
                fontFamily: "Raleway",
                color: "black",
                fontSize: "50px",
                //  color: "#FFA500"
              }}
            >
              Easy installation options
            </Title>

            <Paragraph
              style={{
                fontFamily: "Raleway",
                fontSize: "16px",
              }}
            >
              Our software installation process offers 3 easy modes to suit your
              needs. Whether you prefer a guided installation, Plug and Play, or
              a direct delivery and you want to do the installation process
              manually, we've got you covered. Choose the option that works best
              for you and enjoy a hassle-free installation experience.
            </Paragraph>
          </Col>
        </Col>
        {/* <TitleCustom/> */}
      </Row>
      <Row gutter={[16, 16]} justify="center">
        <Col xl={5} lg={6} md={20} sm={20} xs={20}>
          <OfferCard
            pic={delivery}
            title="Deliver, Install & Train"
            meta={data[0].meta}
          />
        </Col>
        <Col xl={5} lg={6} md={20} sm={20} xs={20}>
          <OfferCard
            pic={installation}
            title="Plug And Play
          (If you have Android TV)
          "
            meta={data[1].meta}
          />
        </Col>
        <Col xl={5} lg={6} md={20} sm={20} xs={20}>
          {/* <div style={{ display: "flex" }}> */}
          <OfferCard
            pic={selfinstallation}
            title="Delivery"
            meta={data[2].meta}
          />
          {/* <div className="three-rounds">
              <img src={morerounds} style={{ zIndex: "1", height: "150px" }} />
            </div> */}
          {/* </div> */}
        </Col>
      </Row>
      {/* <Row justify="center" >
        <Button
          size="large"
          style={{
            width: "150px",
            height: "50px",
            color: "white",
            backgroundColor: "black",
            marginBlock: "20px",
            letterSpacing: "1.5px",
            fontWeight: "500",
            fontSize: "18px",
            border: "none",
          }}
          icon={<WechatOutlined />}
          onClick={() => {}}
        >
          Let's Talk
        </Button>
      </Row> */}
    </div>
  );
};

export default Info;
