import React, { useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import logo from "../images/headerlogo.png";
import "../styles.css";
import { UnorderedListOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const Header = ({
  handleClick,
  home,
  ourclients,
  info,
  features,
  plans,
  setActive,
  active,
}) => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const handleClose = (location) => {
    handleClick(location);
    setNav(false);
  };
  return (
    <div
      style={{
        fontFamily: "Raleway",
      }}
    >
      {nav && (
        <div
          id="mySidepanel"
          className="sidepanel"
          style={{ fontFamily: "Raleway" }}
        >
          <Row justify="end">
            <Button
              // className="closebtn"
              type="text"
              icon={<CloseOutlined style={{ fontSize: "25px" }} />}
              style={{ marginRight: "25px", marginBottom: "30px" }}
              onClick={() => setNav(false)}
            ></Button>
          </Row>
          <Row
            justify="center"
            style={{ height: "30px", fontSize: "30px", paddingBlock: "50px" }}
          >
            <a href="#home" onClick={() => setNav(false)}>
              Home
            </a>
          </Row>{" "}
          <Row
            justify="center"
            style={{ height: "30px", fontSize: "30px", paddingBlock: "50px" }}
          >
            <a href="#info" onClick={() => setNav(false)}>
              Offers
            </a>
          </Row>{" "}
          <Row
            justify="center"
            style={{ height: "30px", fontSize: "30px", paddingBlock: "50px" }}
          >
            <a href="#features" onClick={() => setNav(false)}>
              Features
            </a>
          </Row>{" "}
          <Row
            justify="center"
            style={{ height: "30px", fontSize: "30px", paddingBlock: "50px" }}
          >
            <a href="#plans" onClick={() => setNav(false)}>
              Plans
            </a>
          </Row>
        </div>
      )}

      <Row justify="center">
        <Col xl={22} xs={4}>
          <Row align="middle">
            <Col span={12}>
              <img
                //   className="logo"
                style={{ height: "80px", padding: "0.5rem" }}
                src={logo}
                alt="logo"
              ></img>
            </Col>
            <Col
              xl={{ span: 12 }}
              md={{ span: 0 }}
              sm={{ span: 0 }}
              xs={{ span: 0 }}
            >
              <Row justify="space-around" align="middle">
                <Col>
                  <Button
                    type="link"
                    style={{
                      color: "#FFA500",
                      fontFamily: "Raleway",
                      // fontWeight: "500",
                      fontSize: "17px",
                    }}
                    onClick={() => handleClick(home)}
                  >
                    Home
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="link"
                    // className="button"
                    style={{
                      color: "black",
                      fontFamily: "Raleway",
                      fontSize: "17px",
                    }}
                    onClick={() => handleClick(info)}
                  >
                    What we offer
                  </Button>
                </Col>

                <Col>
                  <Button
                    type="link"
                    style={{
                      color: "black",
                      fontFamily: "Raleway",
                      fontSize: "17px",
                    }}
                    onClick={() => handleClick(features)}
                  >
                    Features
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="link"
                    style={{
                      color: "black",
                      fontFamily: "Raleway",
                      fontSize: "17px",
                    }}
                    onClick={() => handleClick(plans)}
                  >
                    Pricing Plans
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="text"
                    size="large"
                    style={{
                      backgroundColor: "#FFA500",
                      // color: "white",
                      fontSize: "18px",
                      fontWeight: "600",
                      borderStyle: "solid",
                      borderColor: "black",
                    }}
                    onClick={() => navigate("../scheduledemo")}

                    // onClick={() =>
                    //   (window.location =
                    //     "https://calendly.com/riyash2521/schedule-a-demo")
                    // }
                  >
                    Schedule Demo
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={20} md={20} lg={0} xl={0} flex="auto">
          <div
            className="dropdown"
            style={{ float: "right", marginTop: "20px", marginRight: "10px" }}
          >
            <Button
              className="drop-btn"
              icon={
                <UnorderedListOutlined
                  style={{
                    justifyContent: "right",
                    display: "flex",
                    // color: "white",
                    fontSize: "30px",
                    fontWeight: "700",
                    // padding: "10px",
                  }}
                />
              }
              style={
                {
                  // borderColor: "#12111f",
                }
              }
              type="text"
              onClick={() => setNav(true)}
            >
              <div className="dropdown-content">
                <button
                  onClick={() => {
                    handleClick(home);
                    // setActive({
                    //   home: true,
                    //   buyer: false,
                    //   seller: false,
                    //   ourworks: false,
                    // });
                  }}
                >
                  HOME
                </button>
                <button
                  onClick={() => {
                    handleClick(info);
                    // setActive({
                    //   home: false,
                    //   buyer: true,
                    //   seller: false,
                    //   ourworks: false,
                    // });
                  }}
                >
                  WHAT WE OFFER
                </button>
                <button
                  onClick={() => {
                    handleClick(features);
                    // setActive({
                    //   home: false,
                    //   buyer: false,
                    //   seller: true,
                    //   ourworks: false,
                    // });
                  }}
                >
                  FEATURES
                </button>
                <button
                  onClick={() => {
                    handleClick(plans);
                    // setActive({
                    //   home: false,
                    //   buyer: false,
                    //   seller: false,
                    //   ourworks: true,
                    // });
                  }}
                >
                  PRICING PLANS
                </button>
                <button>SCHEDULE DEMO</button>
              </div>
            </Button>
          </div>
        </Col>
        {/* <Col span={15}>
          <Row justify="center">
            {" "}
            <Divider
              style={{ borderColor: "#FFA500", marginBlock: "0px" }}
            ></Divider>
          </Row>
        </Col> */}
      </Row>
    </div>
  );
};

export default Header;
