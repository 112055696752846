import { Col, Row } from "antd";
import React from "react";
import TextBox from "../Components/TextBox";
import mainframe from "../images/main.webp";
import mainframe2 from "../images/newmain.webp";
const Home = () => {
  return (
    <div id="home">
      <Row align="top" justify="space-evenly">
        <Col
          xl={12}
          lg={24}
          md={12}
          sm={{ span: 24, order: 2 }}
          xs={{ span: 24, order: 2 }}
          // style={{ backgroundColor: "violet" }}
        >
          <TextBox />
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={{ span: 24, order: 1 }}
          xs={{ span: 24, order: 1 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // backgroundColor: "pink",
            }}
          >
            <img
              src={mainframe}
              style={{
                overflow: "hidden",
                padding: "30px",
                // height: "600px",
                width: "80%",
                borderRadius: "30px",

                // borderColor: "black",
                // borderWidth: "3px",
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
