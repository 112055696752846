import React from "react";
import {
  CalendarOutlined,
  DollarCircleFilled,
  CheckCircleFilled,
  DoubleRightOutlined,
  DollarOutlined,
  CheckSquareFilled,
  StarFilled,
  CrownFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import plant from "../images/plant1.png";

const NewNewCard = () => {
  return (
    <div id="plans" style={{ paddingBlock: "40px" }}>
      <Row justify="center" align="middle">
        <Col xl={24} xs={0}>
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
              fontSize: "45px",
              marginTop: "40px",
              fontFamily: "Raleway",
            }}
          >
            {" "}
            Start your free trial now
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xl={8} sm={24} xs={24}>
          <div
            style={{
              fontFamily: "Raleway",
              // marginBottom: "10%",
              // marginTop: "10%",
              padding: "25px",
              height: "100%",
              // backgroundColor: "violet",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xl={16} xs={22}>
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "45px",
                  fontFamily: "Raleway",
                }}
              >
                {" "}
                Watch out for our prices
              </h1>
              {/* <Divider style={{ borderColor: "#FBB248" }} /> */}
              <p
                style={{
                  lineHeight: "28px",
                  fontSize: "16px ",
                  fontFamily: "Raleway",
                }}
              >
                We understand that a direct buyout model can be heavy on your
                pockets at times. Considering that we offer a rental
                subscription model as well apart from the lumpsum model with way
                better features.
              </p>
            </Col>
          </div>
        </Col>
        <Col xl={16} sm={24} xs={24}>
          <div
            // direction="vertical"
            style={{
              fontFamily: "Raleway",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Row justify="center">
              <Col xl={12} sm={24} xs={24} style={{ padding: "10px" }}>
                <Card
                  hoverable={true}
                  bordered={true}
                  style={{
                    // width: 350,
                    justifyContent: "center",
                    display: "flex",
                    // marginRight: "15px",
                    backgroundColor: "#fef8e6",
                    padding: "10px",
                    // borderRadius: "10px",
                    // borderStyle: "solid",
                  }}
                >
                  <div style={{ fontFamily: "Raleway" }}>
                    <Row style={{ fontFamily: "Raleway" }} align="middle">
                      <StarFilled style={{ marginRight: "10px" }} />

                      <h1>Rental</h1>
                    </Row>
                    <Row align="middle">
                      <span
                        class="material-symbols-outlined"
                        style={{
                          fontSize: "35px",
                          opacity: "0.5",
                        }}
                      >
                        currency_rupee
                      </span>

                      <Col>
                        <span style={{ fontSize: "45px" }}>1500</span>
                      </Col>
                      <Col>
                        <span
                          style={{
                            opacity: "0.75",
                            marginLeft: "10px",
                          }}
                        >
                          / per month
                        </span>
                      </Col>
                    </Row>
                  </div>

                  <div style={{ fontFamily: "Raleway" }}>
                    <Row justify="center" align="middle">
                      <Col span={24} style={{ padding: "10px" }}>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "10px" }}
                          />
                          <span style={{ fontSize: "16px" }}>
                            {" "}
                            All Features
                          </span>
                        </Row>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />

                          <span style={{ fontSize: "16px" }}>
                            24/7 Call Support
                          </span>
                        </Row>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          <span style={{ fontSize: "16px" }}>
                            On-site support
                          </span>
                        </Row>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          <span style={{ fontSize: "16px" }}>
                            Free Hardware
                          </span>
                        </Row>{" "}
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          <span style={{ fontSize: "16px" }}>
                            Free Installation
                          </span>
                        </Row>
                        <Row justify="center">
                          <Button
                            size="large"
                            style={{
                              width: "200px",
                              height: "50px",
                              color: "white",
                              backgroundColor: "#FBC02D",
                              marginTop: "15px",
                              letterSpacing: "1.5px",
                              fontWeight: "500",
                              fontSize: "18px",
                              border: "none",
                            }}
                            // icon={<DoubleRightOutlined />}
                            href="tel:+917020858404"
                          >
                            Call Now
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col xl={12} sm={24} xs={24} style={{ padding: "10px" }}>
                <Card
                  hoverable={true}
                  bordered={true}
                  style={{
                    // width: 350,
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#FBC02D",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  <div>
                    <Row style={{ fontFamily: "Raleway" }} align="middle">
                      <CrownFilled style={{ marginRight: "10px" }} />

                      <h1>Lumpsum</h1>
                    </Row>
                    <Row align="middle">
                      <Col>
                        <span
                          class="material-symbols-outlined"
                          style={{
                            fontSize: "35px",
                            opacity: "0.5",
                          }}
                        >
                          currency_rupee
                        </span>
                      </Col>
                      <Col>
                        <span style={{ fontSize: "45px" }}>1.26</span>
                      </Col>
                      <Col>
                        <span
                          style={{
                            opacity: "0.75",
                            marginLeft: "10px",
                          }}
                        >
                          lakh
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ fontFamily: "Raleway", color: "white" }}>
                    <Row justify="center" align="middle">
                      <Col xl={24} style={{ padding: "10px" }}>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                          />
                          <span style={{ fontSize: "16px" }}>
                            {" "}
                            Limited Features
                          </span>
                        </Row>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />

                          <span style={{ fontSize: "16px" }}>Chat support</span>
                        </Row>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <span>
                            <CheckSquareFilled
                              style={{ fontSize: "20px", marginRight: "5px" }}
                            />
                            <span style={{ fontSize: "16px" }}>
                              Extra hardware cost
                            </span>
                          </span>
                        </Row>
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <span>
                            <CheckSquareFilled
                              style={{ fontSize: "20px", marginRight: "5px" }}
                            />
                            <span style={{ fontSize: "16px" }}>
                              1 year software warranty
                            </span>
                          </span>
                        </Row>{" "}
                        <Row
                          justify="start"
                          align="middle"
                          style={{ marginBlock: "20px" }}
                        >
                          <CheckSquareFilled
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          <span style={{ fontSize: "16px" }}>
                            AMC applicable
                          </span>
                        </Row>
                        <Row justify="center">
                          <Button
                            size="large"
                            style={{
                              width: "200px",
                              height: "50px",
                              color: "#FBC02D",
                              backgroundColor: "white",
                              marginTop: "15px",
                              letterSpacing: "1.5px",
                              fontWeight: "500",
                              fontSize: "18px",
                              border: "none",
                            }}
                            // icon={<DoubleRightOutlined />}

                            href="tel:+917020858404"
                          >
                            Call Now
                          </Button>
                        </Row>
                      </Col>{" "}
                    </Row>
                  </div>
                </Card>
              </Col>{" "}
            </Row>{" "}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewNewCard;
