import { FloatButton } from "antd";
import React, { useRef, useState } from "react";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import NewCard from "./Components/NewCard";
import NewNewCard from "./Components/NewNewCard";
import PriceCard from "./Components/PriceCard";
import Strip from "./Components/Strip";
import Clients from "./Pages/Clients";
import Diagram from "./Pages/Diagram";
import Features from "./Pages/Features";
import FeaturesNew from "./Pages/FeaturesNew";
import Home from "./Pages/Home";
import Info from "./Pages/Info";
import OurClients from "./Pages/OurClients";
import OurFeatures from "./Pages/OurFeatures";
import Plans from "./Pages/Plans";
import whatsapp from "./images/whatsapp.png";
import { WhatsAppOutlined } from "@ant-design/icons";
import w from "./images/wicon.png";
import MoreFeatures from "./Components/MoreFeatures";
import Video from "./Pages/Video";

const App = () => {
  const home = useRef(null);
  const features = useRef(null);
  const info = useRef(null);
  const ourclients = useRef(null);
  const plans = useRef(null);
  const [active, setActive] = useState({
    home: true,
    ourclients: false,
    info: false,
    features: false,
    plans: false,
  });

  const handleClick = (location) => {
    setActive({
      home: home === location ? true : false,
      ourclients: ourclients === location ? true : false,
      info: info === location ? true : false,
      features: features === location ? true : false,
      plans: plans === location ? true : false,
    });
    let id = "home";
    home === location
      ? (id = "home")
      : ourclients === location
      ? (id = "ourclients")
      : info === location
      ? (id = "info")
      : features === location
      ? (id = "features")
      : (id = "plans");

    const yOffset = 0;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    if (location == home) {
      window.scrollTo({
        top: (0, 0),
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };
  return (
    <div>
      <a
        href="https://wa.me/917020858404"
        target="_blank"
        className="float"
        style={{ zIndex: 101 }}
      >
        <i className="fa fa-whatsapp my-float"></i>
      </a>
      <Header
        handleClick={handleClick}
        home={home}
        ourclients={ourclients}
        info={info}
        features={features}
        plans={plans}
        active={active}
        setActive={setActive}
      />
      <Home />
      <Strip bg="other-bg" />
      {/* <OurClients /> */}
      <Info />
      <Clients />
      <Diagram />
      <Strip bg="other-bg" />
      {/* <Features /> */}
      {/* <FeaturesNew/>
       */}
      <OurFeatures />
      <Video />
      {/* <Strip bg="black-flipped" /> */}
      {/* <Plans /> */}
      {/* <PriceCard /> */}
      {/* <NewCard/> */}
      <NewNewCard />
      {/* <MoreFeatures /> */}
      <Footer
        handleClick={handleClick}
        home={home}
        ourclients={ourclients}
        info={info}
        features={features}
        plans={plans}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};

export default App;
