import { Spin } from "antd";
import React, { useEffect, useState } from "react";

const Calendly = () => {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // document.addEventListener(
      //   "afterprint",
      //   function () {
      //     setVisible(false);
      //   },
      //   false
      // );
      document.body.removeChild(script);
    };
  }, []);

  // window.onload = function () {
  //   alert("loaded");
  //   setVisible(false);
  // };

  return (
    <>
      {/* <div>
        <Spin spinning={visible}></Spin>
      </div> */}
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/coppercodes/ideogram-digital-signage-demo?hide_gdpr_banner=1"
        style={{ minWidth: "320px", height: "630px" }}
      ></div>
    </>
  );
};

export default Calendly;
