import { Col, Row } from "antd";
import React from "react";
import ReactPlayer from "react-player";
const Video = () => {
  return (
    <div>
      <Row justify="center">
        <Col xl={12} xs={20}>
          <h1
            style={{
              // display: "flex",
              // justifyContent: "center",
              fontSize: "35px",
              fontFamily: "Raleway",
            }}
          >
            Live example of how our product functions
          </h1>
        </Col>
      </Row>
      {/* <ReactPlayer url="https://drive.google.com/file/d/11WVpOvya227zm_RHv-PpdpTr2tsOiaip/view?usp=share_link" /> */}
      <Row justify="center">
        <video controls width="80%">
          <source
            src="https://coppercodes.nyc3.digitaloceanspaces.com/ideogram/videogram.mp4"
            type="video/mp4"
          />
        </video>
      </Row>
    </div>
  );
};

export default Video;
