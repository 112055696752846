import React from "react";
import logo from "../images/headerlogo.png";
import "../styles.css";
import { Button, Col, Divider, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const Footer = ({
  handleClick,
  home,
  ourclients,
  info,
  features,
  plans,
  setActive,
  active,
}) => {
  const navigate = useNavigate();
  let date = new Date().getFullYear();
  return (
    <div
      style={{
        backgroundColor: "#FBC02D",
        fontFamily: "Raleway",
        // marginTop: "140px",
        // marginBottom: "100px",
        // fontColor: "#12111f",
        // padding: "40px",
      }}
    >
      <Row gutter={16} justify="start" align="top" style={{ padding: "40px" }}>
        <Col xl={6}>
          <img style={{ height: "6rem", padding: "0.5rem" }} src={logo}></img>
        </Col>
        <Col
          xl={6}
          xs={0}
          // style={{
          //   fontFamily: "Raleway",
          //   fontSize: "16px",
          //   letterSpacing: "2px",
          // }}
        >
          <p
            style={{
              fontFamily: "Raleway",
              fontSize: "16px",
              letterSpacing: "2px",
            }}
          >
            {" "}
            <a
              // href="#home"
              onClick={() => handleClick(home)}
            >
              Home
            </a>
          </p>
          <p
            style={{
              fontFamily: "Raleway",
              fontSize: "16px",
              letterSpacing: "2px",
            }}
          >
            {" "}
            <a onClick={() => handleClick(info)}>What we offer</a>
          </p>
          <p
            style={{
              fontFamily: "Raleway",
              fontSize: "16px",
              letterSpacing: "2px",
            }}
            onClick={() => handleClick(features)}
          >
            {" "}
            <a
            // href="#features"
            >
              Features
            </a>
          </p>
          <p
            style={{
              fontFamily: "Raleway",
              fontSize: "16px",
              letterSpacing: "2px",
            }}
          >
            {" "}
            <a onClick={() => handleClick(plans)}>Pricing Plans</a>
          </p>
          <Row justify="start">
            <Button
              type="text"
              size="large"
              style={{
                width: "165px",
                backgroundColor: "black",
                color: "white",
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "20px",
                letterSpacing: "2px",
              }}
              onClick={() => navigate("../scheduledemo")}
              // onClick={() => handleClick(home)}
            >
              Schedule Demo
            </Button>
          </Row>
        </Col>
        <Col
          xl={6}
          style={{
            fontFamily: "Raleway",
            fontSize: "16px",
            letterSpacing: "2px",
          }}
        >
          <p> S1, 2nd Flr, Alankar Apts,</p>
          <p>Opp. Stella Maris Chapel,</p>
          <p>Miramar, Panaji, Goa.</p> <p>403001</p>
          <br />
          <p style={{ fontFamily: "sans-serif" }}>+91 7020858404</p>
        </Col>
        
        <Col
          xl={6}
          xs={6}
          style={{
            fontFamily: "Raleway",
            fontSize: "16px",
            fontWeight: "700",
            letterSpacing: "2px",
          }}
        >
          <p>
            {" "}
            <a
              href="https://www.facebook.com/profile.php?id=100090381707007&mibextid=ZbWKwL"
              target="_blank"
            >
              Facebook
            </a>
          </p>
          <p>
            <a
              href="https://www.instagram.com/ideogramsignages/"
              target="_blank"
            >
              Instagram
            </a>
          </p>
          <p>
            {" "}
            <a
              href="https://www.linkedin.com/company/ideogramdigitalsignages/"
              target="_blank"
            >
              LinkedIn
            </a>
          </p>
          <br />
          <br />
          <p
            style={{
              fontFamily: "Raleway",
              fontSize: "14px",
              fontWeight: "400",
              letterSpacing: "2px",
            }}
          >
            Email- <br />
            <a
              href="mailto: ideogramsignages@gmail.com"
              style={{
                fontFamily: "Raleway",
                fontSize: "16px",
                fontWeight: "700",
                letterSpacing: "2px",
              }}
            >
              ideogramsignages@gmail.com
            </a>
          </p>
        </Col>
      </Row>
      <Row justify="center">
        <Col xl={0} xs={20}>
          <Button
            type="text"
            size="large"
            style={{
              backgroundColor: "black",
              color: "white",
              fontSize: "16px",
              fontWeight: "500",
              marginBottom: "30px",
              letterSpacing: "2px",
            }}
            onClick={() => navigate("../scheduledemo")}
            // onClick={() => handleClick(home)}
          >
            Schedule Demo
          </Button>
        </Col>
      </Row>
      <Row style={{ backgroundColor: "black", padding: "10px" }}>
        <Col
          span={24}
          style={{ color: "white", display: "flex", justifyContent: "center" }}
        >
          {" "}
          <span>
            {" "}
            © {date} by Ideogram. Powered and secured by{" "}
            <a target="_blank" href="https://coppercodes.com/">
              CopperCodes
            </a>
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
