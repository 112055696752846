import { Col, Divider, Row } from "antd";
import React from "react";
import diagram from "../images/talkbox.webp";
import diagramlg from "../images/talkbox-lg.webp";

const Diagram = () => {
  return (
    <Row justify="center" style={{ marginTop: "50px" }}>
      <Row justify="center">
        <Col xl={24} xs={20}>
          {" "}
          <h1
            style={{
              fontWeight: "600",
              fontSize: "50px",
              fontFamily: "Raleway",
            }}
          >
            {" "}
            Where you can install Ideogram?
          </h1>
        </Col>
      </Row>
      {/* <Col xl={12} xs={24}>
        <div
          style={{
            fontFamily: "Raleway",
            // marginBottom: "10%",
            // marginTop: "10%",
            // padding: "25px",
            height: "100%",
            // backgroundColor: "violet",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xl={16} lg={16} md={24} sm={24} xs={20}>
            <h1
              style={{
                fontWeight: "600",
                fontSize: "45px",
                fontFamily: "Raleway",
              }}
            >
              {" "}
              Where you can install Ideogram?
            </h1>
            
            <p
              style={{
                lineHeight: "28px",
                fontSize: "16px ",
                fontFamily: "Raleway",
              }}
            >
              I'm a paragraph. Click here to add your own text and edit me. It’s
              easy. Just click “Edit Text” or double click me to add your own
              content and make changes to the font. I’m a great place for you to
              tell a story and let your users know a little more about you.
            </p>
          </Col>
        </div>
      </Col> */}
      <Row>
        <Col xl={24} xs={0}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={diagramlg}
              style={{
                overflow: "hidden",
                padding: "30px",
                // height: "600px",
                width: "80%",
                //   backgroundColor: "pink",
              }}
            ></img>
          </div>
        </Col>
        <Col xl={0} xs={24}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={diagram}
              style={{
                overflow: "hidden",
                padding: "30px",
                // height: "600px",
                width: "80%",
                //   backgroundColor: "pink",
              }}
            ></img>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default Diagram;
