import { Button, Col, Divider, Row, Space } from "antd";
import {
  CalendarOutlined,
  CalendarFilled,
  SafetyCertificateOutlined,
  SafetyCertificateFilled,
  DoubleRightOutlined,
  CarryOutFilled,
  PhoneFilled,
} from "@ant-design/icons";
import splitscreen from "../images/split-screen.png";
import creative from "../images/creative.png";
import idea from "../images/idea.png";
import schedule from "../images/schedule.png";
import remote from "../images/remote-control.png";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";

const TextBox = () => {
  return (
    <Col xl={{ span: 24 }} style={{ fontFamily: "Raleway" }}>
      <div
        style={{
          //   fontFamily: "Raleway",
          marginBottom: "10%",
          // marginTop: "10%",
          padding: "25px",
          height: "100%",
          width: "90%",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "1000",
              fontSize: "55px",
            }}
          >
            <span style={{ color: "#FFA500", fontSize: "65px" }}>
              {" "}
              Ideogram
            </span>{" "}
            - A Cloud Controlled Digital Signage Solution
          </h1>
          {/* <Divider style={{ borderColor: "#FBB248" }} /> */}
        </div>

        <Paragraph
          level={5}
          style={{
            color: "grey",
            fontWeight: "400",
            fontSize: "17px",
            lineHeight: "28px",
            fontFamily: "Raleway",
          }}
        >
          The perfect way to engage your audience and elevate your brand. With
          our powerful software platform, you can create, manage, and distribute
          captivating content to digital displays in various locations.
        </Paragraph>

        {/* <div
          style={{
            display: "flex",
            marginTop: "50px",
            fontSize: "18px",
            backgroundColor: "grey",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <CalendarFilled />

            <div style={{ marginLeft: "10px" }}>
              <span style={{ fontSize: "16px" }}>Flexibility</span> <br />{" "}
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SafetyCertificateFilled />

            <div style={{ marginLeft: "10px" }}>
              <span style={{ fontSize: "16px" }}> Reusability</span> <br />{" "}
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CarryOutFilled />

            <div style={{ marginLeft: "10px" }}>
              <span style={{ fontSize: "16px" }}> Fast Delivery</span> <br />{" "}
            </div>
          </div>
        </div> */}
        <Row align="middle">
          <Col xl={12} xs={0}>
            <Row align="middle">
              <Col span={4}>
                {" "}
                <img src={idea} style={{ width: "40px" }}></img>
              </Col>
              <Col span={10}>
                <p
                  style={{
                    fontSize: "15px",
                    // marginLeft: "10px",
                    fontFamily: "Raleway",
                  }}
                >
                  {" "}
                  Create your own content
                </p>
              </Col>
            </Row>
          </Col>
          <Col xl={12} xs={0}>
            {" "}
            <Row align="middle">
              <Col span={4}>
                {" "}
                <img src={splitscreen} style={{ width: "40px" }}></img>
              </Col>
              <Col span={10}>
                <p
                  style={{
                    fontSize: "15px",
                    // marginLeft: "10px",
                    fontFamily: "Raleway",
                  }}
                >
                  Split Screen
                </p>
              </Col>
            </Row>
          </Col>
          <Col xl={0} xs={24} style={{ marginBlock: "10px" }}>
            {" "}
            <Row align="middle" justify="center">
              <img src={idea} style={{ width: "60px" }}></img>
            </Row>
            <Row justify="center">
              <p
                style={{
                  fontSize: "16px",
                  // marginLeft: "10px",
                  fontFamily: "Raleway",
                }}
              >
                {" "}
                Create your own content
              </p>
            </Row>
          </Col>
          <Col xl={0} xs={24} style={{ marginBlock: "10px" }}>
            {" "}
            <Row align="middle" justify="center">
              <img src={splitscreen} style={{ width: "60px" }}></img>
            </Row>
            <Row justify="center">
              <p
                style={{
                  fontSize: "16px",
                  // marginLeft: "10px",
                  fontFamily: "Raleway",
                }}
              >
                Split Screen
              </p>
            </Row>
          </Col>
        </Row>
        <Row align="middle">
          <Col xl={12} xs={0}>
            <Row align="middle">
              <Col span={4}>
                {" "}
                <img src={schedule} style={{ width: "40px" }}></img>
              </Col>
              <Col span={10}>
                <p
                  style={{
                    fontSize: "15px",
                    // marginLeft: "10px",
                    fontFamily: "Raleway",
                  }}
                >
                  Schedule Images/Videos
                </p>
              </Col>
            </Row>
          </Col>
          <Col xl={12} xs={0}>
            {" "}
            <Row align="middle">
              <Col span={4}>
                {" "}
                <img src={remote} style={{ width: "40px" }}></img>
              </Col>
              <Col span={10}>
                <p
                  style={{
                    fontSize: "15px",
                    // marginLeft: "10px",
                    fontFamily: "Raleway",
                  }}
                >
                  Display Content Remotely
                </p>
              </Col>
            </Row>
          </Col>
          <Col xl={0} xs={24} style={{ marginBlock: "10px" }}>
            {" "}
            <Row align="middle" justify="center">
              <img src={schedule} style={{ width: "60px" }}></img>
            </Row>
            <Row justify="center">
              <p
                style={{
                  fontSize: "16px",
                  // marginLeft: "10px",
                  fontFamily: "Raleway",
                }}
              >
                Schedule Images/Videos
              </p>
            </Row>
          </Col>
          <Col xl={0} xs={24} style={{ marginBlock: "10px" }}>
            {" "}
            <Row align="middle" justify="center">
              <img src={remote} style={{ width: "60px" }}></img>
            </Row>
            <Row justify="center">
              <p
                style={{
                  fontSize: "16px",
                  // marginLeft: "10px",
                  fontFamily: "Raleway",
                }}
              >
                Display Content Remotely
              </p>
            </Row>
          </Col>
        </Row>

        <Row align="middle">
          <Col xl={24} xs={0}>
            <Button
              size="large"
              style={{
                width: "150px",
                height: "50px",
                color: "white",
                backgroundColor: "#FFA500",
                marginTop: "15px",
                letterSpacing: "1.5px",
                fontWeight: "500",
                fontSize: "18px",
              }}
              icon={<PhoneFilled />}
              href="tel:+91 7020858404"
            >
              Call Now
            </Button>
          </Col>
        </Row>
        {/* </Row> */}
      </div>
    </Col>
  );
};

export default TextBox;
